import { getPermissions, getFeatureFlags } from '../redux/selectors';
import { FeatureFlagSettings, StoreData } from "../models/App";
import config from "../app-config";

/**
 * Translates permissions and other feature dependencies (like catalog data)
 * into booleans for use in UI rendering/API endpoint protection
 *
 * @param {string[]} _permissions - a list of the current user's permissions/roles extracted
 * from auth0's ID token supplied at login.  Implements role-based access on a per-user basis.
 * @param {FeatureFlagSettings} _featureFlags - current state of internal feature flags, fetched from API on login.
 * Hides/exposes various UI feature elements.
 * @param {boolean} _showAnalytics - boolean indicating whether this console instance has supplied
 * a valid google analytics config.  Determines whether to show components displaying GA data.
 */
function createFeatureDecisions (_permissions: string[], _featureFlags: FeatureFlagSettings, _showAnalytics = false) {
  const permissions = _permissions ? _permissions : [];
  const featureFlags = _featureFlags ? _featureFlags : {} as FeatureFlagSettings;
  const showAnalytics = _showAnalytics !== null ? _showAnalytics : false;

  return {
    showExperimentalFeatures() {
      return permissions.includes('Experimental Features')
    },
    // permissions are extracted from the user's ID token in App.tsx
    // they are assigned via a user's roles on the auth0 dashboard
    // the Channel Admin role allows users to see admin-only UI - feature flags and WebSetup
    exposeAdminUI() {
      return permissions.includes('Channel Admin')
    },
    exposeAzureUI() {
      return config.REACT_APP_AZURE_AUTH === 'true'
    },
    exposeContentGroups() {
      return !!featureFlags.exposeContentGroups?.enabled
    },
    // indicates whether a valid GA config has been saved to the API.
    // controls whether GA widgets appear on dashboard, and whether add'l links
    // appear in sidebar under Google Analytics
    showAnalytics() {
      return !!featureFlags.exposeGoogleAnalytics?.enabled && showAnalytics
    },
    // indicates whether the GoogleAnalyticsVersion flag is enabled.
    // controls whether Google Analytics menu appears in sidebar, allowing user to save a GA config to the API
    exposeAnalytics() {
      return !!featureFlags.exposeGoogleAnalytics?.enabled
    },
    exposeResourceCenter() {
      return !!featureFlags.exposeResourceCenter?.enabled
    },
    // exposes fields used by clients that are not integrated with an ERP,
    // such as the custom field 'Price"
    nonIntegratedClientFlag() {
      return !!featureFlags.nonIntegratedClient?.enabled
    },
    exposeSalesRep() {
      return !!featureFlags.exposeSalesRep?.enabled
    },
    exposeACHReports() {
      return !!featureFlags.exposeACHReports?.enabled
    },
    exposeCoupons() {
      return !!featureFlags.exposeCoupons?.enabled
    },
    exposeShipAddresses() {
      return !!featureFlags.exposeShipAddresses?.enabled
    },
    exposeShipRates() {
      return !!featureFlags.exposeShipRates?.enabled
    },
    enableCostCenters() {
      return !!featureFlags.enableCostCenters?.enabled
    },
    exposeApprovalGroups() {
      return !!featureFlags.exposeApprovalGroups?.enabled
    },
    exposeProductDocuments() {
      return !!featureFlags.exposeProductDocuments?.enabled
    },
    exposeProductInventoryMessage() {
      return !!featureFlags.exposeProductInventoryMessage?.enabled
    },
    exposeOrderHistory() {
      return !!featureFlags.exposeOrderHistory?.enabled
    },
    exposeApprovalBudget() {
      return !!featureFlags.exposeApprovalBudget?.enabled
    },
    exposeLoginPricing() {
      return !!featureFlags.showPricing?.enabled
    },
    exposeERPCompanyId() {
      return !!featureFlags.exposeERPCompanyId?.enabled
    },
    exposeNewProductImport() {
      return !!featureFlags.exposeNewProductImport?.enabled
    },
    exposeCategoryGridView() {
      return !!featureFlags.exposeCategoryGridView?.enabled
    }
  }
}
export default createFeatureDecisions

/**
 * Returns a list of all feature decision functions. Used to determine whether the user
 * has the correct permissions to call an endpoint.
 * @param {StoreData} store - current state of data in redux store. contains a list of:
 * permissions (roles) taken from the user's id token on login;
 * catalog data fetched from the API;
 * whether the client has a google analytics account linked to this console instance;
 * and the state of the frontend feature flags, fetched from the API on login
 */
export function createFeatureDecisionsFromStore(store: StoreData) {
  const permissions = getPermissions(store);
  const featureFlags = getFeatureFlags(store)
  const showAnalytics = store.analytics;

  return createFeatureDecisions(permissions, featureFlags, showAnalytics)
}
